<template>
  <div>
    <u-button
      leading-icon="i-heroicons-arrow-left"
      to="/"
      class="mx-4 mb-6 mt-8"
    >
      Back
    </u-button>
    <div
      class="prose prose-neutral dark:prose-invert lg:prose-xl mx-auto mb-16 mt-8 px-4 text-inherit dark:text-inherit"
    >
      <slot />
    </div>
  </div>
</template>
